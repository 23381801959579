import React from 'react';
import { usePackingListDetailContext } from '../context';

const PackingWarnings = () => {
    const { packageData } = usePackingListDetailContext();

    const warnings = packageData?.packedPurchaseOrderDetails?.reduce<string[]>((acc, item) => {
        item.packedSkus.forEach((sku) => {
            if (sku.warnings) acc.push(...sku.warnings);
        });
        return acc;
    }, []);

    if (!warnings || warnings.length === 0) return null;

    return (
        <div
            className="flex items-center px-4 py-2 -mt-6 text-[#d9ae00] rounded-lg bg-bg-gray font-bold border border-gray-200 "
            role="alert"
        >
            <svg
                className="flex-shrink-0 inline w-8 h-8 me-3 mt-[2px] mr-4"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
            >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
            </svg>
            <div>
                <ul className="mt-1.5 list-disc list-inside">
                    {warnings.map((warning, index) => (
                        <li key={index} className="mb-[1px]">
                            {warning}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default PackingWarnings;
