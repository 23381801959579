import { DefaultButton, IIconProps } from '@fluentui/react';
import { saveAs } from 'file-saver';
import React from 'react';
import { toast } from 'react-toastify';

import { CustomSelect } from '../../../common/select';
import { DEFAULT_PRODUCTION_FOLLOW_FILTERS, productionFollowOrderOpts } from '../../../consts';
import { EProductionFollowOrderBy } from '../../../enums';
import { useFilterSearchParams } from '../../../helpers/hooks/orders';
import { notifyRequestErr, productionOrderMap } from '../../../helpers/utils';
import { LoaderContext } from '../../../routes/router';
import { orderService } from '../../../services/orderService';
import { IOrderFilter } from '../../../store/models.ts/common';
import { useShippingAndTrackingContext } from '../context/shippingAndTracking';

export interface IProps {
    totalElementCount: number;
}

export const ActionBar: React.FC<IProps> = ({ totalElementCount }) => {
    const downloadExcelIcon: IIconProps = { iconName: 'Download' };
    const { setLoadMoreData, toggleEditModal, checkedRows } = useShippingAndTrackingContext();
    const ctxt = React.useContext(LoaderContext);
    const [filters, updateFilters] = useFilterSearchParams();

    const handleOrderChange = (value: string) => {
        const updatedFilters: IOrderFilter = {
            ...DEFAULT_PRODUCTION_FOLLOW_FILTERS,
            ...productionOrderMap(value),
        };
        updateFilters(updatedFilters);
        setLoadMoreData(false);
    };

    const handleDownloadDocument = () => {
        ctxt?.setLoaderIsActive(true);
        return orderService
            .downloadShippingDetailExcel(filters)
            .then((res) => {
                fetch(
                    `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${res.data}`,
                )
                    .then((res) => res.blob())
                    .then((blobRes) => {
                        const blob = new Blob([blobRes], {
                            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        });
                        saveAs(blob, res.fileName);
                    });
                toast.success('File successfully downloaded!', {
                    position: toast.POSITION.TOP_CENTER,
                });
                ctxt?.setLoaderIsActive(false);
            })
            .catch((err: any) => {
                ctxt?.setLoaderIsActive(false);
                notifyRequestErr(err);
            });
    };

    return (
        <div className="bg-bg-gray border-bottom py-4 sticky left-0 top-0 z-10">
            <div className="flex justify-between items-center ml-[80px] mr-[30px]">
                <div className="flex items-center">
                    <DefaultButton
                        text="Edit"
                        allowDisabledFocus
                        className="mr-4"
                        type="submit"
                        onClick={() => toggleEditModal(false)}
                        disabled={!checkedRows.length}
                    />
                </div>
                <div className="top-header-right-part flex items-center justify-between">
                    <span className="mr-10">
                        Total: <b>{totalElementCount ?? 0}</b>
                    </span>
                    <span>Sort by:</span>
                    <CustomSelect
                        options={productionFollowOrderOpts as any}
                        border="none"
                        backGroundColor="transparent"
                        width={200}
                        onChange={handleOrderChange}
                        placeholder="Choose one"
                        defaultValue={EProductionFollowOrderBy.LATEST_DELIVERY_DATE}
                        fontWeight="700"
                    />
                    <DefaultButton
                        text="Download excel"
                        allowDisabledFocus
                        className="ml-4 mr-[11px]"
                        type="submit"
                        onClick={handleDownloadDocument}
                        iconProps={downloadExcelIcon}
                    />
                </div>
            </div>
        </div>
    );
};
