import { Checkbox, TableCell, TableRow } from '@mui/material';
import moment from 'moment';
import React, { useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ROUTES } from '../../../../consts';
import { handleIfCheckBoxChecked, notifyRequestErr } from '../../../../helpers/utils';
import p30 from '../../../../images/27x28.png';
import { LoaderContext } from '../../../../routes/router';
import { orderService } from '../../../../services/orderService';
import { IOrderElems } from '../../../../store/models.ts/common';
import { downloadBase64DataAsFile } from '../../../../utilities';
import { useShippingAndTrackingContext } from '../../context/shippingAndTracking';

interface IProps {
    order: IOrderElems;
}

export const ShippingAndTrackingTableRow: React.FC<IProps> = ({ order }) => {
    const ctxt = React.useContext(LoaderContext);
    const { checkedRows, setCheckedRows } = useShippingAndTrackingContext();

    const handleCheckChanges = () => {
        if (checkedRows.find((id) => id === order.document.poDocId)) {
            setCheckedRows((prev) => prev.filter((id) => id !== order.document.poDocId));
        } else {
            setCheckedRows((prev) => [...prev, order.document.poDocId]);
        }
    };

    const handleDownloadFileWithUrl = useCallback((url: string) => {
        ctxt?.setLoaderIsActive(true);
        return orderService
            .downloadBase64File(url.substring(1))
            .then((res) => {
                downloadBase64DataAsFile(res.data, res.fileName)
                    .then(() => {
                        toast.success('File successfully downloaded!', {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    })
                    .catch((err) => {
                        notifyRequestErr(err);
                    })
                    .finally(() => {
                        ctxt?.setLoaderIsActive(false);
                    });
            })
            .catch((err: any) => {
                ctxt?.setLoaderIsActive(false);
                notifyRequestErr(err);
            });
    }, []);

    // const openEditModal = () => {
    //     setCheckedRows([order.document.poDocId]);
    //     toggleEditModal(true);
    // };

    return (
        <TableRow
            key={order.document.poDocId}
            className={`t-row pr-4 parent-row mt-2 ${
                handleIfCheckBoxChecked(checkedRows, order.document.poDocId) && 'bg-orange-50'
            }`}
        >
            {/* <TableCell className="border-none pr-0 pl-3">
                <button
                    onClick={openEditModal}
                    className="cursor-pointer flex gap-2 items-center text-black  px-2 py-0.5 rounded-sm"
                >
                    <EditIcon />
                </button>
            </TableCell> */}
            <TableCell className="border-none">
                <Checkbox
                    color="primary"
                    indeterminate={false}
                    checked={handleIfCheckBoxChecked(checkedRows, order.document.poDocId)}
                    onChange={handleCheckChanges}
                />
            </TableCell>
            <TableCell className="border-none">{order.document.seasonDelivery ?? '-'}</TableCell>
            <TableCell className="border-none">
                {order.document.sgtAuditRequiredDs ?? '-'}
            </TableCell>
            <TableCell className="border-none">{order.document.styleType ?? '-'}</TableCell>
            <TableCell className="border-none max-w-[225px]">
                {order.document.vendorDs ?? '-'}
            </TableCell>
            <TableCell className="border-none">{order.document.destination ?? '-'}</TableCell>
            <TableCell className="border-none">
                <NavLink
                    to={`../${ROUTES.ORDER_DETAILS}/${order.document.poDocId}?isMaster=false`}
                    className="underline"
                >
                    {order.document.orderIdToBeDisplayed ?? '-'}
                </NavLink>
            </TableCell>
            <TableCell className="border-none">
                <div className="flex items-center">
                    <img src={p30} className="mr-[5px]" alt="photo" />
                    <p className="mr-3">{order.document.style}</p>
                </div>
            </TableCell>
            <TableCell className="border-none whitespace-normal min-w-[200px]">
                {order.document.colorDescription ?? '-'}
            </TableCell>
            <TableCell className="border-none whitespace-normal min-w-[200px]">
                {order.document.styleDesc ?? '-'}
            </TableCell>
            <TableCell className="border-none">{order.document.originalQuantity}</TableCell>
            <TableCell className="border-none text-center">
                {order.document.totalShippedQuantity ?? '-'}
            </TableCell>
            <TableCell className="border-none text-center">
                {order.document.qtyDifference ?? '-'}
            </TableCell>
            <TableCell className="border-none text-center">
                {order.document.sgtAuditStatusDs ?? '-'}
            </TableCell>
            <TableCell className="border-none text-center">
                {order.document.sgtAuditResultGetBase64Url ? (
                    <span
                        className="underline cursor-pointer hover:opacity-70 duration-100"
                        onClick={() =>
                            handleDownloadFileWithUrl(order.document.sgtAuditResultGetBase64Url)
                        }
                    >
                        {order.document.sgtAuditResultFileName &&
                            (order.document.sgtAuditResultFileName.length >= 20
                                ? `${order.document.sgtAuditResultFileName.substring(0, 17)}...`
                                : order.document.sgtAuditResultFileName)}
                    </span>
                ) : (
                    '-'
                )}
            </TableCell>
            <TableCell className="border-none text-center">
                {order.document.packingListGetBase64Url ? (
                    <span
                        className="underline cursor-pointer hover:opacity-70 duration-100"
                        onClick={() =>
                            handleDownloadFileWithUrl(order.document.packingListGetBase64Url)
                        }
                    >
                        {order.document.packingListFileName &&
                            (order.document.packingListFileName.length >= 20
                                ? `${order.document.packingListFileName.substring(0, 17)}...`
                                : order.document.packingListFileName)}
                    </span>
                ) : (
                    '-'
                )}
            </TableCell>
            <TableCell className="border-none text-center">
                {order.document.invoiceGetBase64Url ? (
                    <span
                        className="underline cursor-pointer hover:opacity-70 duration-100"
                        onClick={() =>
                            handleDownloadFileWithUrl(order.document.invoiceGetBase64Url)
                        }
                    >
                        {order.document.invoiceFileName &&
                            (order.document.invoiceFileName.length >= 20
                                ? `${order.document.invoiceFileName.substring(0, 17)}...`
                                : order.document.invoiceFileName)}
                    </span>
                ) : (
                    '-'
                )}
            </TableCell>
            <TableCell className="border-none text-center">
                {order.document.handOverDate
                    ? moment(order.document.handOverDate).format('MM-DD-YYYY')
                    : '-'}
            </TableCell>
            <TableCell className="border-none text-center">
                {order.document.awb ? (
                    <a
                        href={order.document.awbUrl ?? '#'}
                        target={order.document.awbUrl ? '_blank' : '_self'}
                        className="hover:underline"
                    >
                        {order.document.awb}
                    </a>
                ) : (
                    '-'
                )}
            </TableCell>
            <TableCell className="border-none text-center">
                {order.document.expectedDepartureDate
                    ? moment(order.document.expectedDepartureDate).format('MM-DD-YYYY')
                    : '-'}
            </TableCell>
            <TableCell className="border-none text-center">
                {order.document.poInDcDate
                    ? moment(order.document.poInDcDate).format('MM-DD-YYYY')
                    : '-'}
            </TableCell>
            <TableCell className="border-none text-center">
                {order.document.expectedDeliveryDate
                    ? moment(order.document.expectedDeliveryDate).format('MM-DD-YYYY')
                    : '-'}
            </TableCell>
            <TableCell className="border-none text-center">
                {order.document.receivedDate
                    ? moment(order.document.receivedDate).format('MM-DD-YYYY')
                    : '-'}
            </TableCell>
            <TableCell className="border-none text-center">
                {order.document.totalReceivedQuantity ?? '-'}
            </TableCell>
            <TableCell className="border-none text-center">
                {order.document.deliveryStatus ?? '-'}
            </TableCell>
        </TableRow>
    );
};
