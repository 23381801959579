import { Table, TableContainer } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useMemo, useRef } from 'react';

import { Async, asyncRenderError } from '../../../common/async';
import { SkeletonElement } from '../../../common/skeleton';
import { QUERY_KEYS } from '../../../consts';
import { useOnScreen } from '../../../helpers/hooks';
import { useFilterSearchParams, useShippingTrackingOrders } from '../../../helpers/hooks/orders';
import { notifyRequestErr } from '../../../helpers/utils';
import { orderService } from '../../../services/orderService';
import { IOrderElems } from '../../../store/models.ts/common';
import { useShippingAndTrackingContext } from '../context/shippingAndTracking';
import { ActionBar } from './actionbar';
import { ShippingAndTrackingTableBody } from './body';
import { ShippingAndTrackingTableHeader } from './header';

let lastAsyncOrderData: IOrderElems[];
const ShippingAndTrackingTable = () => {
    const [filters, updateFilters] = useFilterSearchParams();
    const queryClient = useQueryClient();
    const {
        isLoadMoreData,
        setLoadMoreData,
        orders: contextOrders,
        setOrders: setContextOrders,
    } = useShippingAndTrackingContext();
    const ref = useRef<HTMLDivElement>(null);
    const [isVisible, observe] = useOnScreen(ref);
    const asyncOrders = useShippingTrackingOrders(filters);
    const { setLogisticsCompanies } = useShippingAndTrackingContext();

    useEffect(() => () => queryClient.removeQueries([QUERY_KEYS.SHIPPING_TRACKING_ORDER]), []);

    const hasMoreItems = useMemo(() => {
        if (!asyncOrders.data || !asyncOrders.data.elements) return false;
        return asyncOrders.data.totalResults > contextOrders.length;
    }, [asyncOrders, contextOrders]);

    useEffect(() => {
        if (asyncOrders.isSuccess) observe();
    }, [asyncOrders]);

    useEffect(() => {
        if (
            !asyncOrders.isSuccess ||
            !asyncOrders.data.elements ||
            lastAsyncOrderData === asyncOrders.data.elements
        )
            return;

        setContextOrders(
            isLoadMoreData
                ? [...contextOrders, ...asyncOrders.data.elements]
                : asyncOrders.data.elements,
        );
        lastAsyncOrderData = asyncOrders.data.elements;
        setLoadMoreData(null);
    }, [asyncOrders]);

    useEffect(() => {
        if (
            !isVisible ||
            !asyncOrders.data ||
            !asyncOrders.data.elements ||
            asyncOrders.isFetching ||
            !asyncOrders.isSuccess
        )
            return;

        updateFilters({
            ...filters,
            searchAfter: asyncOrders.data.elements.at(-1)?.sorts ?? [],
        });
        setLoadMoreData(true);
    }, [isVisible, asyncOrders]);

    useEffect(() => {
        orderService
            .getLogisticsCompanies()
            .then((res) => setLogisticsCompanies(res))
            .catch((err) => {
                setLogisticsCompanies([]);
                notifyRequestErr(err);
            });
    }, []);

    return (
        <TableContainer>
            <Async
                branch={asyncOrders}
                renderSuccess={(res) => (
                    <div className="6xl:h-[calc(100vh-220px)]  h-[calc(100vh-260px)] overflow-x-auto help">
                        <ActionBar totalElementCount={res.totalResults} />
                        <Table
                            className="production_follow_table"
                            style={{
                                tableLayout: 'auto',
                            }}
                        >
                            <ShippingAndTrackingTableHeader />
                            <ShippingAndTrackingTableBody />
                            {!asyncOrders.isRefetching &&
                                hasMoreItems &&
                                contextOrders.length !== 0 && (
                                    <div className="w-full h-6 sticky left-0" ref={ref}></div>
                                )}
                        </Table>
                        {asyncOrders.isFetching && (
                            <SkeletonElement
                                itemCount={2}
                                animation={'wave'}
                                className="p-8 pt-1 pb-5 width-full sticky left-0"
                            />
                        )}
                    </div>
                )}
                renderEmpty={<p>Empty</p>}
                renderError={asyncRenderError}
                renderLoading={
                    <SkeletonElement itemCount={14} className="p-8 pt-11 pb-5 height-full" />
                }
            />
        </TableContainer>
    );
};

export default ShippingAndTrackingTable;
