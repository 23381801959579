import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { toast } from 'react-toastify';

import { useOnScreen } from '../../../helpers/hooks';
import { packingListService } from '../../../services/packingList';
import { usePackingListContext } from '../context';
import TableDataRow from './row';

const PackingListTable = () => {
    const {
        setListLoading,
        setPackingLists,
        packingLists,
        isListLoading,
        paginationInfo,
        setPaginationInfo,
    } = usePackingListContext();
    const ref = useRef<HTMLDivElement>(null);
    const [isVisible, observe] = useOnScreen(ref);

    const hasMore = useMemo(
        () => paginationInfo.pageIndex <= paginationInfo.totalPages,
        [paginationInfo],
    );

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (paginationInfo.searchKey !== undefined) {
            getData();
        }
    }, [paginationInfo.searchKey]);

    useEffect(() => {
        if (!isListLoading && isVisible) {
            getData(true);
        }
    }, [isVisible, packingLists, isListLoading]);

    const getData = useCallback(
        (isLoadMore = false) => {
            setListLoading(true);
            packingListService
                .getPackingLists(paginationInfo)
                .then((data) => {
                    const { elements, ...rest } = data;
                    rest.pageIndex += 1;
                    rest.searchKey = paginationInfo.searchKey;
                    setPaginationInfo(rest);
                    const newPackingLists = isLoadMore ? [...packingLists, ...elements] : elements;
                    setPackingLists(newPackingLists);
                    observe();
                })
                .catch(() => {
                    toast.error('Something went wrong! Please try again.');
                })
                .finally(() => {
                    setListLoading(false);
                });
        },
        [packingLists, paginationInfo, setListLoading, setPackingLists],
    );

    return (
        <Table
            className="border-x rounded-lg"
            style={{
                boxShadow:
                    '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
            }}
        >
            <TableHead className="bg-bg-gray py-10 sticky top-[188px] z-10">
                <TableRow>
                    <TableCell className="border-none">ID</TableCell>
                    <TableCell className="border-none">Date</TableCell>
                    <TableCell className="border-none">Vendor</TableCell>
                    <TableCell className="border-none">PO#</TableCell>
                    <TableCell className="border-none">Carton QTY</TableCell>
                    <TableCell className="border-none">Packed QTY</TableCell>
                    <TableCell className="border-none">Status</TableCell>
                    <TableCell className="border-none w-10"></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {packingLists.map((packingList) => (
                    <TableDataRow key={packingList.packingListId} packingList={packingList} />
                ))}
                {isListLoading &&
                    new Array(4).fill(0).map((_, idx) => <TableDataRow.Skeleton key={idx} />)}
            </TableBody>
            {hasMore && <div className="h-2 w-full" ref={ref}></div>}
        </Table>
    );
};

export default PackingListTable;
