import { Spinner, SpinnerSize } from '@fluentui/react';
import { Menu } from '@headlessui/react';
import { Skeleton, TableCell, TableRow } from '@mui/material';
import moment from 'moment';
import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ROUTES } from '../../../consts';
import { EPackingListStatus } from '../../../enums';
import { notifyRequestErr } from '../../../helpers/utils';
import { ReactComponent as EditIcon } from '../../../images/icons/edit.svg';
import { ReactComponent as TrashIcon } from '../../../images/icons/trash.svg';
import { packingListService } from '../../../services/packingList';
import { IPackingList } from '../../../store/models.ts/common';
import { usePackingListContext } from '../context';

const statusMap = {
    [EPackingListStatus.DRAFT]: {
        text: 'Draft',
        color: '#797979',
        backgroundColor: '#E9E9E9',
    },
    [EPackingListStatus.CONFIRMED]: {
        text: 'Confirmed',
        color: '#027A48',
        backgroundColor: '#ECFDF3',
    },
    [EPackingListStatus.CANCELLED]: {
        text: 'Canceled',
        color: '#FF4D4F',
        backgroundColor: '#FFF1F0',
    },
};

export const StatusBadge: React.FC<{ statusId: number }> = ({ statusId }) => {
    const status = statusMap[statusId];
    const colors = {
        dotColor: status.color,
        backgroundColor: status.backgroundColor,
    };
    return (
        <div
            className="px-2.5 py-1.5 flex items-center gap-x-2 w-fit rounded-2xl"
            style={{ backgroundColor: colors.backgroundColor }}
        >
            <div
                className="w-2.5 h-2.5 rounded-full"
                style={{ backgroundColor: colors.dotColor }}
            />
            <span>{status.text}</span>
        </div>
    );
};

interface ITableDataRowProps {
    packingList: IPackingList;
}

const TableDataRow = ({ packingList }: ITableDataRowProps) => {
    return (
        <TableRow>
            <TableCell>{packingList.packingListId ?? '-'}</TableCell>
            <TableCell>{moment(packingList.createdOn).format('MM-DD-YYYY')}</TableCell>
            <TableCell>{packingList.vendorDescription ?? '-'}</TableCell>
            <TableCell>
                <p className="max-w-[200px] truncate">{packingList.poIdsToBeDisplayed}</p>
            </TableCell>
            <TableCell>{packingList.packagesCount}</TableCell>
            <TableCell>{packingList.totalPackedQty}</TableCell>
            <TableCell>
                <StatusBadge statusId={packingList.statusId} />
            </TableCell>
            <TableCell className="h-[80px]">
                <div className="flex items-center gap-x-1 ">
                    <CancelAction packingList={packingList} />
                    <Link
                        to={`/${ROUTES.PACKING_LIST}/${packingList.packingListId}`}
                        className="p-2.5 hover:bg-gray-100 rounded-md transition"
                    >
                        <EditIcon />
                    </Link>
                </div>
            </TableCell>
        </TableRow>
    );
};

const CancelAction = ({ packingList }: { packingList: IPackingList }) => {
    const { setPackingLists, packingLists } = usePackingListContext();
    const [loading, setLoading] = useState(false);

    const isCanceled = packingList.statusId === EPackingListStatus.CANCELLED;

    const cancelPackingList = useCallback(() => {
        setLoading(true);
        packingListService
            .updatePackingList({
                packingListId: packingList.packingListId,
                statusId: EPackingListStatus.CANCELLED,
            })
            .then((data) => {
                const index = packingLists.findIndex(
                    (p) => p.packingListId === packingList.packingListId,
                );
                if (index !== -1) {
                    packingLists[index].statusId = data.statusId;
                    setPackingLists([...packingLists]);
                }
                toast.success('Packing list canceled successfully');
            })
            .catch((err) => {
                notifyRequestErr(err);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [packingList, packingLists, setPackingLists]);
    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button
                    disabled={isCanceled || loading}
                    className="p-2.5 hover:bg-gray-100 rounded-md transition disabled:cursor-not-allowed disabled:bg-transparent disabled:opacity-70"
                >
                    {loading ? <Spinner size={SpinnerSize.small} /> : <TrashIcon />}
                </Menu.Button>
            </div>
            <Menu.Items className="absolute right-0 mt-2 w-[300px] origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none z-[40]">
                <div className="px-1 py-1">
                    <Menu.Item disabled>
                        {() => (
                            <ul className="list-disc  text-red-500 text-xs px-4 py-2">
                                <li className="my-2">
                                    After cancelling packing list you will not be able to update or
                                    confirm packing list.
                                </li>
                                <li className="font-bold">
                                    ATTENTION - After cancelling this PL, please remove all the
                                    printed labels from the boxes.
                                </li>
                            </ul>
                        )}
                    </Menu.Item>
                    <Menu.Item>
                        {() => (
                            <button
                                onClick={cancelPackingList}
                                className={`hover:bg-red-500 hover:text-white transition group flex w-full items-center justify-center rounded-md px-6 py-2 text-sm`}
                            >
                                Confirm Cancel
                            </button>
                        )}
                    </Menu.Item>
                </div>
            </Menu.Items>
        </Menu>
    );
};

TableDataRow.Skeleton = function TableDataRowSkeleton() {
    return (
        <TableRow>
            <TableCell colSpan={8}>
                <Skeleton className="w-full py-3" />
            </TableCell>
        </TableRow>
    );
};

export default TableDataRow;
