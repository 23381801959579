import { IconButton, Modal, TextField, mergeStyleSets } from '@fluentui/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { ControlledDatePicker } from '../../../common/controledDatePicker';
import { CustomSelect } from '../../../common/select';
import { iconButtonStyles, modalContentStyles } from '../../../consts';
import { notifyRequestErr } from '../../../helpers/utils';
import { LoaderContext } from '../../../routes/router';
import { orderService } from '../../../services/orderService';
import { cancelIcon } from '../../order/EditModal/consts';
import { useShippingAndTrackingContext } from '../context/shippingAndTracking';
import { TrackingEditDropzone } from './dropzone';
import { usePermission } from '../../../helpers/hooks';
import { IOption, ISgtAuditStatus } from '../../../store/models.ts/common';

export const EditModal: React.FC = () => {
    const ctxt = React.useContext(LoaderContext);
    const [uploadLoading, setUploadLoading] = useState(false);
    const [invoiceUploadLoading, setInvoiceUploadLoading] = useState(false);
    const [sgtAuditUploadLoading, setSgtAuditUploadLoading] = useState(false);
    const [editLoading, setEditLoading] = useState(false);
    const [sgtAuditStatusOptions, setSgtAuditStatusOptions] = useState<IOption[]>([]);
    const [uploadedFile, setUploadedFile] = useState<{
        filePath: string;
        fileName: string;
        size?: number;
    } | null>(null);
    const [uploadedInvoiceFile, setUploadedInvoiceFile] = useState<{
        filePath: string;
        fileName: string;
        size?: number;
    } | null>(null);
    const [uploadedSgtAuditFile, setUploadedSgtAuditFile] = useState<{
        filePath: string;
        fileName: string;
        size?: number;
    } | null>(null);
    const {
        checkedRows,
        setCheckedRows,
        closeEditModal,
        isEditModalOpen,
        orders,
        setOrders,
        logisticsCompanies,
    } = useShippingAndTrackingContext();
    const { handleSubmit, control, reset, setValue, getValues } = useForm({
        defaultValues: {
            handOverDate: '',
            trackingNumber: '',
            logisticsCompanyId: '',
            sgtAuditStatusId: '',
        },
    });
    const { sgtAuditAllowed, sgtAuditApprovalAllowed } = usePermission();

    useEffect(() => {
        if (!isEditModalOpen) {
            setUploadedFile(null);
            setUploadedInvoiceFile(null);
            setUploadedSgtAuditFile(null);
            return;
        }
        reset();
        if (checkedRows.length !== 1) return;
        const order = orders.find((order) => order.document?.poDocId === checkedRows[0]);
        if (!order) return;
        setValue('handOverDate', order.document.handOverDate);
        setValue('trackingNumber', order.document.awb);
        setValue('logisticsCompanyId', order.document.logisticsCompanyId);
        if (order.document.sgtAuditStatusId) {
            setValue('sgtAuditStatusId', String(order.document.sgtAuditStatusId));
        }
        if (order.document.packingListUrl) {
            setUploadedFile({
                filePath: order.document.packingListUrl,
                fileName: order.document.packingListFileName,
            });
        }
        if (order.document.invoiceUrl) {
            setUploadedInvoiceFile({
                filePath: order.document.invoiceUrl,
                fileName: order.document.invoiceFileName,
            });
        }
        if (order.document.sgtAuditResultUrl) {
            setUploadedSgtAuditFile({
                filePath: order.document.sgtAuditResultUrl,
                fileName: order.document.sgtAuditResultFileName,
            });
        }
    }, [isEditModalOpen]);

    useEffect(() => {
        orderService
            .getSgtAuditStatuses()
            .then((data) => {
                setSgtAuditStatusOptions(
                    (data as ISgtAuditStatus[]).map((item) => ({
                        key: String(item.statusId),
                        text: item.description,
                    })),
                );
            })
            .catch((err) => {
                notifyRequestErr(err);
            });
    }, []);

    const onSubmit = useCallback(
        (data) => {
            setEditLoading(true);
            ctxt?.setLoaderIsActive(true);
            const newData = {
                poDocIds: [...checkedRows],
                awb: data.trackingNumber || null,
                logisticsCompanyId: data.logisticsCompanyId || null,
                sgtAuditStatusId: data.sgtAuditStatusId ? Number(data.sgtAuditStatusId) : null,
                handOverDate:
                    (data.handOverDate && data.handOverDate[4] !== '-'
                        ? `${data.handOverDate.slice(6)}-${data.handOverDate.slice(0, 5)}`
                        : data.handOverDate) || null,
                packingListUrl: uploadedFile ? uploadedFile.filePath : null,
                invoiceUrl: uploadedInvoiceFile ? uploadedInvoiceFile.filePath : null,
                sgtAuditResultUrl: uploadedSgtAuditFile ? uploadedSgtAuditFile.filePath : null,
            };
            orderService
                .editShippingInfo(newData)
                .then((res) => {
                    setCheckedRows([]);
                    closeEditModal();
                    if (!res || res.length < 1) return;
                    res.forEach((item) => {
                        const idx = orders.findIndex(
                            (order) => order.document.poDocId === item.poDocId,
                        );
                        orders[idx].document = item;
                    });
                    setOrders([...orders]);
                })
                .catch((err) => {
                    notifyRequestErr(err);
                })
                .finally(() => {
                    ctxt?.setLoaderIsActive(false);
                    setEditLoading(false);
                });
        },
        [checkedRows, uploadedFile, uploadedInvoiceFile, uploadedSgtAuditFile],
    );

    return (
        <Modal
            titleAriaId={'ddd'}
            isOpen={isEditModalOpen}
            onDismiss={() => closeEditModal()}
            isBlocking={false}
            containerClassName={
                mergeStyleSets({
                    container: {
                        display: 'flex',
                        flexFlow: 'column nowrap',
                        alignItems: 'stretch',
                        backGroundColor: '#F8F8F8',
                        width: 'fit-content',
                        height: 'fit-content',
                        font: 'normal normal medium 14px/18px Circular XX',
                        overflow: 'hidden',
                    },
                }).container
            }
            className="edit-pre-modal"
        >
            <div className={modalContentStyles.header}>
                <b id="dsd" className="text-black bold head flex items-center">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-8 w-8 mr-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                        />
                    </svg>
                    Tracking Details
                </b>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    onClick={() => closeEditModal()}
                />
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-wrap xl:flex-nowrap gap-x-6 py-4 px-6">
                    <ControlledDatePicker
                        label="Hand Over Date"
                        control={control}
                        name="handOverDate"
                        placeholder="Select a date ..."
                        className="w-[250px]"
                    />
                    <Controller
                        control={control}
                        name="logisticsCompanyId"
                        render={({ field: { value, onChange } }) => (
                            <CustomSelect
                                label="Logistics Company"
                                options={
                                    (logisticsCompanies ?? []).map((item) => ({
                                        key: item.companyId,
                                        text: item.companyDs,
                                    })) as any
                                }
                                placeholder="Select an option"
                                width={250}
                                color="black"
                                borderColor="#605e5c"
                                onChange={onChange}
                                value={value}
                                selectedKey={getValues('logisticsCompanyId')}
                                dropdownHeight={500}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="trackingNumber"
                        render={({ field: { value, onChange } }) => (
                            <TextField
                                label="Tracking #"
                                type="text"
                                value={value}
                                onChange={(...event) => {
                                    onChange(...event);
                                }}
                                placeholder="-"
                                className="w-[250px]"
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        name="sgtAuditStatusId"
                        render={({ field: { value, onChange } }) => (
                            <CustomSelect
                                label="Audit Status"
                                disabled={!sgtAuditApprovalAllowed}
                                options={sgtAuditStatusOptions}
                                placeholder="Select an option"
                                width={250}
                                color="black"
                                borderColor="#605e5c"
                                onChange={onChange}
                                value={value}
                                selectedKey={getValues('sgtAuditStatusId')}
                                dropdownHeight={500}
                            />
                        )}
                    />
                </div>
                <div className="px-6">
                    <p className="-mb-6 font-semibold">Packing List</p>
                    <TrackingEditDropzone
                        uploadedFile={uploadedFile}
                        uploadLoading={uploadLoading}
                        setUploadedFile={setUploadedFile}
                        setUploadLoading={setUploadLoading}
                        uploadService={orderService.uploadShippingFile}
                    />
                    <p className="-mb-6 font-semibold mt-4">Invoice</p>
                    <TrackingEditDropzone
                        uploadedFile={uploadedInvoiceFile}
                        uploadLoading={invoiceUploadLoading}
                        setUploadedFile={setUploadedInvoiceFile}
                        setUploadLoading={setInvoiceUploadLoading}
                        uploadService={orderService.uploadInvoiceFile}
                    />
                    <p className="-mb-6 font-semibold mt-4">Audit Result</p>
                    <TrackingEditDropzone
                        disabled={!sgtAuditAllowed}
                        uploadedFile={uploadedSgtAuditFile}
                        uploadLoading={sgtAuditUploadLoading}
                        setUploadedFile={setUploadedSgtAuditFile}
                        setUploadLoading={setSgtAuditUploadLoading}
                        uploadService={orderService.uploadSgtAuditFile}
                    />
                </div>
                <div className="flex justify-end mt-8 mb-5 mr-6">
                    <button
                        disabled={uploadLoading || editLoading}
                        type="submit"
                        className="py-[10px] text-[18px] pl-[35px] pr-[35px] bg-black text-white hover:opacity-90 duration-100 disabled:opacity-50 disabled:cursor-wait"
                    >
                        Save details
                    </button>
                </div>
            </form>
        </Modal>
    );
};
