import { BaseUrl } from '../consts';
import { loginRequest } from '../helpers/authConfig';
import { msalInstance } from '../helpers/authHelper';
import {
    IGetPackingListResponse,
    IPackingList,
    IPackingListPackageData,
    IPackingListPackageResponse,
    IPackingListPurchaseOrder,
} from '../store/models.ts/common';
import { postRequest } from '../utilities/rest';

class PackingListService {
    private getAuthHead = async (): Promise<
        | {
              authorization: string;
              pragma: string;
              cacheControl: string;
          }
        | any
    > => {
        const accounts = msalInstance.getAllAccounts();

        const res = await msalInstance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
        });

        return {
            authorization: `Bearer ${res.accessToken}`,
            pragma: 'no-cache',
            'cache-control': 'no-cache',
        };
    };

    getPackingLists = async ({
        packinglistId,
        pageIndex,
        pageSize,
        searchKey = '',
    }: {
        packinglistId?: number;
        pageIndex: number;
        pageSize: number;
        searchKey?: string;
    }): Promise<IGetPackingListResponse> => {
        return await postRequest(
            `${BaseUrl}packing/packing-list`,
            {
                packinglistId,
                pageIndex,
                pageSize,
                searchKey,
            },
            {
                headers: await this.getAuthHead(),
            },
        );
    };

    createPackingList = async (data: { vendorId: string }): Promise<IPackingList> => {
        return await postRequest(`${BaseUrl}packing/packing-list/create`, data, {
            headers: await this.getAuthHead(),
        });
    };

    updatePackingList = async ({
        statusId,
        packingListId,
    }: {
        statusId: number;
        packingListId: number;
    }): Promise<IPackingList> => {
        return await postRequest(
            `${BaseUrl}packing/packing-list/update`,
            { statusId, packingListId },
            {
                headers: await this.getAuthHead(),
            },
        );
    };

    getPurchaseOrders = async ({
        packinglistid,
    }: {
        packinglistid: number;
    }): Promise<IPackingListPurchaseOrder[]> => {
        return await postRequest(
            `${BaseUrl}packing/po?packinglistid=${packinglistid}`,
            {},
            {
                headers: await this.getAuthHead(),
            },
        );
    };

    getAvailablePurchaseOrders = async ({
        packinglistid,
    }: {
        packinglistid: number;
    }): Promise<IPackingListPurchaseOrder[]> => {
        return await postRequest(
            `${BaseUrl}packing/po/available?packinglistid=${packinglistid}`,
            {},
            {
                headers: await this.getAuthHead(),
            },
        );
    };

    addPurchaseOrder = async ({
        packingListId,
        poDocId,
    }: {
        packingListId: number;
        poDocId: string;
    }): Promise<IPackingListPurchaseOrder[]> => {
        return await postRequest(
            `${BaseUrl}packing/po/add`,
            { packingListId, poDocId },
            {
                headers: await this.getAuthHead(),
            },
        );
    };

    removePurchaseOrder = async ({
        packinglistid,
        podocid,
        size,
    }: {
        packinglistid: number;
        podocid: string;
        size: string;
    }): Promise<IPackingListPurchaseOrder[]> => {
        return await postRequest(
            `${BaseUrl}packing/po/remove`,
            { packinglistid, podocid, size },
            {
                headers: await this.getAuthHead(),
            },
        );
    };

    createPackage = async ({
        packingListId,
        packageContents,
    }: {
        packingListId: number;
        packageContents: { poDocId: string; size: string; packedQty: number }[];
    }): Promise<IPackingListPackageResponse> => {
        return await postRequest(
            `${BaseUrl}packing/package/create`,
            { packingListId, packageContents },
            {
                headers: await this.getAuthHead(),
            },
        );
    };

    updatePackage = async ({
        packageId,
        packingListId,
        packageContents,
    }: {
        packageId: number;
        packingListId: number;
        packageContents: { poDocId: string; size: string; packedQty: number }[];
    }): Promise<IPackingListPackageResponse> => {
        return await postRequest(
            `${BaseUrl}packing/package/update`,
            { packageId, packingListId, packageContents },
            {
                headers: await this.getAuthHead(),
            },
        );
    };

    getPackages = async ({
        packingListId,
        packageid,
    }: {
        packingListId: number;
        packageid?: number;
    }): Promise<IPackingListPackageData> => {
        const urlSearchParams = new URLSearchParams();
        urlSearchParams.append('packingListId', packingListId.toString());
        if (packageid) {
            urlSearchParams.append('packageid', packageid.toString());
        }
        return await postRequest(
            `${BaseUrl}packing/package?${urlSearchParams.toString()}`,
            {},
            {
                headers: await this.getAuthHead(),
            },
        );
    };

    duplicatePackage = async ({
        packageId,
        count,
    }: {
        packageId: number;
        count: number;
    }): Promise<IPackingListPackageResponse> => {
        return await postRequest(
            `${BaseUrl}packing/package/duplicate`,
            { packageId, count },
            {
                headers: await this.getAuthHead(),
            },
        );
    };

    printLabel: any = async (packingListId: number) => {
        return await postRequest(
            `${BaseUrl}packing/packing-list/print-label?packingListId=${packingListId}`,
            {},
            {
                headers: await this.getAuthHead(),
            },
        );
    };

    export = async ({
        pageIndex,
        pageSize,
        searchKey,
    }: {
        pageIndex: number;
        pageSize: number;
        searchKey?: string;
    }) => {
        return await postRequest<any, { data: string; fileName: string }>(
            `${BaseUrl}packing/packing-list/export`,
            { pageIndex, pageSize, searchKey },
            {
                headers: await this.getAuthHead(),
            },
        );
    };
}

export const packingListService = new PackingListService();
