import { Spinner, TextField } from '@fluentui/react';
import { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { ReactComponent as ExportIcon } from '../../images/icons/export.svg';
import { ReactComponent as SearchIcon } from '../../images/icons/search.svg';
import { usePackingListContext } from './context';
import { PACKING_LIST_INITIAL_PAGINATION_INFO } from '../../consts';
import { IPaginationInfo } from '../../store/models.ts/common';
import { packingListService } from '../../services/packingList';
import { toast } from 'react-toastify';
import { downloadBase64DataAsFile } from '../../utilities';

const Actions = () => {
    const { openCreateModal, setPaginationInfo, paginationInfo } = usePackingListContext();
    const [exportLoading, setExportLoading] = useState(false);
    const { handleSubmit, control } = useForm<{ searchKey: string }>({
        defaultValues: {
            searchKey: '',
        },
        reValidateMode: 'onChange',
    });

    const onSubmit = useCallback(({ searchKey }) => {
        const paginationInfo: IPaginationInfo = {
            ...PACKING_LIST_INITIAL_PAGINATION_INFO,
            searchKey,
        };
        setPaginationInfo(paginationInfo);
    }, []);

    const onExport = useCallback(() => {
        setExportLoading(true);
        packingListService
            .export(paginationInfo)
            .then((response) => {
                downloadBase64DataAsFile(response.data, response.fileName);
            })
            .catch(() => {
                toast.error('Something went wrong! Please try again.');
            })
            .finally(() => {
                setExportLoading(false);
            });
    }, [paginationInfo]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-wrap items-center gap-4">
                <Controller
                    control={control}
                    name="searchKey"
                    render={({ field: { value, onChange } }) => (
                        <div className="relative">
                            <TextField
                                value={value}
                                onChange={onChange}
                                placeholder="Search"
                                className="w-72"
                                styles={{
                                    fieldGroup: {
                                        borderColor: '#D0D5DD',
                                        outline: 'none',
                                        height: 40,
                                    },
                                }}
                            />
                        </div>
                    )}
                />
                <button
                    type="submit"
                    className="bg-[#E7E8EC] px-4 py-2.5 flex gap-x-2 hover:bg-[#d3d3d8] transition"
                >
                    <SearchIcon />
                    Search
                </button>
                <button
                    type="button"
                    onClick={onExport}
                    disabled={exportLoading}
                    className="bg-white px-4 py-2.5 flex gap-x-2 border border-gray-300 hover:bg-gray-100/70 transition"
                >
                    <Spinner className="w-5 h-5" hidden={!exportLoading} />
                    {!exportLoading && <ExportIcon />}
                    Export
                </button>
                <button
                    type="button"
                    onClick={openCreateModal}
                    className="bg-black text-white px-4 py-2.5 flex gap-x-2 hover:opacity-80 transition"
                >
                    New Packing List
                </button>
            </div>
        </form>
    );
};

export default Actions;
