import { Popover } from '@headlessui/react';
import React, { useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import Select, { CSSObjectWithLabel, GroupBase, OptionProps } from 'react-select';

import { ReactComponent as ArrowUpIcon } from '../../../images/icons/up-arrow.svg';
import { CSSObject } from '@emotion/styled';

interface ISelectOption {
    value: string;
    label: string;
}

interface IProps {
    fieldName: string;
    filterOption?: any;
    setSearchValues: any;
    searchBarElements: any;
    fieldPlaceholder: string;
    fieldOptionValue: string;
    fieldOptionLabel: string;
    searchBarDataLoading: boolean;
    searchValues: {
        posIds: ISelectOption[];
        styles: ISelectOption[];
        seasons: ISelectOption[];
        vendors: ISelectOption[];
        deliveries: ISelectOption[];
        warehouses: ISelectOption[];
    };
}

const SelectField: React.FC<IProps> = ({
    fieldName,
    filterOption,
    searchValues,
    setSearchValues,
    fieldPlaceholder,
    fieldOptionValue,
    fieldOptionLabel,
    searchBarElements,
    searchBarDataLoading,
}) => {
    const [referenceElement, setReferenceElement] = useState<any>();
    const [popperElement, setPopperElement] = useState<any>();
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: 'bottom-start',
    });
    const options = useMemo(
        () =>
            searchBarElements?.[fieldName]?.map((order) => ({
                value: order[fieldOptionValue],
                label: order[fieldOptionLabel],
            })) || [],
        [searchBarElements, fieldName, fieldOptionValue, fieldOptionLabel],
    );

    return (
        <Popover>
            {(isOpen) => (
                <>
                    <Popover.Button ref={setReferenceElement} className="border-none outline-none">
                        <div className="w-[190px] h-[38px] px-2 flex items-center text-sm text-[rgba(0,0,0,0.42)] border border-[rgb(230,230,230)]">
                            <div>
                                {searchValues &&
                                searchValues[fieldName] &&
                                searchValues[fieldName].length > 0 ? (
                                    <div className="flex items-center">
                                        <span>{fieldPlaceholder}:</span>
                                        <p className="ml-1 text-[rgba(0,0,0,0.7)] w-[60px] px-0.5 truncate text-left rounded-sm">
                                            {options.find(
                                                (o) =>
                                                    o.value ===
                                                    searchValues?.[fieldName]?.[0]?.value,
                                            )?.label ?? ''}
                                        </p>
                                    </div>
                                ) : (
                                    <span>Select {fieldPlaceholder}</span>
                                )}
                            </div>
                            <div className="ml-auto flex gap-1 items-center">
                                {searchValues?.[fieldName]?.length > 1 && (
                                    <p className="ml-auto text-[rgba(0,0,0,0.7)] truncate px-0.5 rounded-sm">
                                        +{searchValues[fieldName].length - 1}
                                    </p>
                                )}
                                <ArrowUpIcon
                                    style={{
                                        transition: '0.4s',
                                        transform: isOpen.open ? 'rotate(180deg)' : 'rotate(0deg)',
                                    }}
                                />
                            </div>
                        </div>
                    </Popover.Button>
                    {ReactDOM.createPortal(
                        <Popover.Panel
                            ref={setPopperElement}
                            style={styles.popper}
                            {...attributes.popper}
                            className="z-40"
                        >
                            <Select
                                isMulti
                                isLoading={searchBarDataLoading}
                                options={options}
                                onChange={(newValue) =>
                                    setSearchValues((prev) => ({
                                        ...prev,
                                        [fieldName]: newValue,
                                    }))
                                }
                                isSearchable={true}
                                filterOption={filterOption}
                                value={options.filter((o) =>
                                    searchValues?.[fieldName]?.find(
                                        (item) => item.value === o.value,
                                    ),
                                )}
                                menuIsOpen={true}
                                styles={getSelectStyles()}
                                closeMenuOnSelect={false}
                                components={{
                                    DropdownIndicator: () => null,
                                    IndicatorSeparator: () => null,
                                }}
                                className="w-[190px]"
                                classNamePrefix="select"
                            />
                        </Popover.Panel>,
                        document.body,
                    )}
                </>
            )}
        </Popover>
    );
};

const getSelectStyles = () => ({
    control: (baseStyles: CSSObjectWithLabel) =>
        ({
            ...baseStyles,
            borderRadius: 0,
            fontSize: '14px',
            minWidth: '190px',
            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
            backgroundColor: 'white',
            borderBottom: '1px solid #C6C6C6',
            width: '190px',
            border: 'none',
        } as CSSObject),
    multiValue: (baseStyles: CSSObjectWithLabel) =>
        ({
            ...baseStyles,
            maxWidth: '190px',
        } as CSSObject),
    option: (baseStyles: CSSObjectWithLabel, state: OptionProps<any, true, GroupBase<any>>) =>
        ({
            ...baseStyles,
            cursor: 'pointer',
            fontSize: '14px',
            backgroundColor: state.isSelected ? '#8D8D8D' : 'inherit',
            '&:hover': {
                backgroundColor: state.isSelected ? '#8D8D8D' : '#F4F4F4',
            },
        } as CSSObject),
    menuPortal: (baseStyles: CSSObjectWithLabel) =>
        ({
            ...baseStyles,
        } as CSSObject),
    menu: (baseStyles: CSSObjectWithLabel) =>
        ({
            ...baseStyles,
            marginTop: 0,
            borderRadius: 0,
            border: 'none',
            borderTop: 'none',
            borderBottomLeftRadius: 4,
            borderBottomRightRadius: 4,
            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
        } as CSSObject),
});

export default SelectField;
