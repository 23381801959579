import { DefaultButton, IIconProps } from '@fluentui/react';
import { UseMutateFunction } from '@tanstack/react-query';
import { saveAs } from 'file-saver';
import React from 'react';
import { toast } from 'react-toastify';

import { CustomSelect } from '../../../common/select';
import {
    DEFAULT_PRODUCTION_FOLLOW_FILTERS,
    productionFollowOrderOpts,
    stageOpts,
} from '../../../consts';
import { EProductionFollowOrderBy } from '../../../enums';
import { useAsyncStages, useFilterSearchParams } from '../../../helpers/hooks/orders';
import { notifyRequestErr, productionOrderMap } from '../../../helpers/utils';
import { LoaderContext } from '../../../routes/router';
import { orderService } from '../../../services/orderService';
import { IOrderFilter } from '../../../store/models.ts/common';
import { ICheckedValues } from '../../../store/models.ts/ProductionStages';
import { useOrderContext } from '../context/orderContext';

interface IProps {
    stageId: string | null;
    totalElementCount: number;
    shouldCleanSelectBox: boolean;
    mutate: UseMutateFunction<any, any, ICheckedValues, unknown>;
    setStageId: React.Dispatch<React.SetStateAction<string | null>>;
    setShouldCleanSelectBox: React.Dispatch<React.SetStateAction<boolean>>;
}
export const ActionBar: React.FC<IProps> = ({
    mutate,
    stageId,
    setStageId,
    totalElementCount,
    shouldCleanSelectBox,
    setShouldCleanSelectBox,
}) => {
    const tableIcon: IIconProps = { iconName: 'Table' };
    const downloadExcelIcon: IIconProps = { iconName: 'Download' };
    const { toggleAdvancedView, isAdvancedActive, toggleEditModal, checkedRows, setLoadMoreData } =
        useOrderContext();
    const { data: stages, isSuccess } = useAsyncStages();
    const ctxt = React.useContext(LoaderContext);
    const [filters, updateFilters] = useFilterSearchParams();

    const stagesOptions: any = isSuccess
        ? stages.map((stage) => ({
              key: stage.productionStageDs,
              text: stage.productionStageDs,
          }))
        : [];

    const handleStageOptChange = (value: string) => {
        const currentStage = stages?.find((stage) => stage.productionStageDs === value);
        setStageId(String(currentStage?.productionStageId));
        setShouldCleanSelectBox(false);
    };

    const handleParentStatusChange = (value: string) => {
        const statusId = stageOpts.find((opt) => opt.key === value)?.index;
        ctxt?.setLoaderIsActive(true);
        if (stageId && statusId) {
            const stagesToChange =
                statusId === '3'
                    ? stages?.slice(0, Number(stageId)).map((stg) => stg.productionStageId)
                    : stages?.slice(Number(stageId)).map((stg) => stg.productionStageId);
            const data: ICheckedValues = {
                poDocIds: checkedRows as string[],
                productionStageId:
                    stageId === '1' && statusId === '1'
                        ? stages?.map((stage) => stage.productionStageId)
                        : stagesToChange,
                productionStatusId: statusId,
            };
            mutate(data);
        }
    };

    const handleOrderChange = (value: string) => {
        const updatedFilters: IOrderFilter = {
            ...DEFAULT_PRODUCTION_FOLLOW_FILTERS,
            ...productionOrderMap(value),
        };
        updateFilters(updatedFilters);
        setLoadMoreData(false);
    };

    const handleDownloadDocument = () => {
        ctxt?.setLoaderIsActive(true);
        return orderService
            .downloadOrderExcel(filters)
            .then((res) => {
                fetch(
                    `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${res.data}`,
                )
                    .then((res) => res.blob())
                    .then((blobRes) => {
                        const blob = new Blob([blobRes], {
                            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        });
                        saveAs(blob, res.fileName);
                    });
                toast.success('File successfully downloaded!', {
                    position: toast.POSITION.TOP_CENTER,
                });
                ctxt?.setLoaderIsActive(false);
            })
            .catch((err: any) => {
                ctxt?.setLoaderIsActive(false);
                notifyRequestErr(err);
            });
    };

    return (
        <div className="bg-bg-gray border-bottom py-4 sticky left-0 top-0 z-10">
            <div className="flex justify-between items-center ml-[80px] mr-[30px]">
                <div className="flex items-center">
                    <DefaultButton
                        text="Edit"
                        allowDisabledFocus
                        className="mr-4"
                        type="submit"
                        onClick={() => toggleEditModal(false)}
                        disabled={!checkedRows.length}
                    />
                    <b>Assign production status to selected</b>
                    <CustomSelect
                        placeholder="Select an option"
                        options={stagesOptions}
                        onChange={handleStageOptChange}
                        className="ml-[25px] mr-[25px] w-[180px]"
                        height={31}
                        lineHeight={30}
                        disabled={!checkedRows.length}
                        clear={shouldCleanSelectBox}
                    />
                    <div className="t-row">
                        <CustomSelect
                            placeholder="Select an option"
                            options={stageOpts as any}
                            onChange={handleParentStatusChange}
                            className="ml-[25px] mr-[25px]"
                            borderRadius={90}
                            width={150}
                            height={26.12}
                            backGroundColor="black"
                            color="white"
                            lineHeight={25}
                            disabled={stageId === null}
                            clear={shouldCleanSelectBox}
                        />
                    </div>
                </div>
                <div className="top-header-right-part flex items-center justify-between">
                    <span className="mr-10">
                        Total: <b>{totalElementCount ?? 0}</b>
                    </span>
                    <span>Sort by:</span>
                    <CustomSelect
                        options={productionFollowOrderOpts as any}
                        border="none"
                        backGroundColor="transparent"
                        width={200}
                        onChange={handleOrderChange}
                        placeholder="Choose one"
                        defaultValue={EProductionFollowOrderBy.LATEST_DELIVERY_DATE}
                        fontWeight="700"
                    />
                    <DefaultButton
                        text="Download excel"
                        allowDisabledFocus
                        className="ml-4 mr-[11px]"
                        type="submit"
                        onClick={handleDownloadDocument}
                        iconProps={downloadExcelIcon}
                    />
                    <DefaultButton
                        text={isAdvancedActive ? 'Simple view' : 'Advanced view'}
                        allowDisabledFocus
                        className="ml-4 mr-[11px]"
                        type="submit"
                        onClick={() => toggleAdvancedView(false)}
                        iconProps={tableIcon}
                    />
                </div>
            </div>
        </div>
    );
};
