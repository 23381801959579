import { BaseUrl } from '../consts';
import { loginRequest } from '../helpers/authConfig';
import { msalInstance } from '../helpers/authHelper';
import { IComment, ICommentRes } from '../store/models.ts/comment';
import {
    IDownloadBase64Res,
    IEditShippingReq,
    ILogisticCompany,
    IOrder,
    IOrderExcelDownloadRes,
    IOrderFilter,
    IOrderRes,
    IUpdateEstimatedReq,
} from '../store/models.ts/common';
import { IPreproductionStageDetail } from '../store/models.ts/ProductionFollowUp';
import { ICheckedValues, IProductionStageRes } from '../store/models.ts/ProductionStages';
import { ITechSheetRes } from '../store/models.ts/techSheet';
import { delRequest, getRequest, postRequest } from '../utilities/rest';

class OrderService {
    private getAuthHead = async (): Promise<
        | {
              authorization: string;
              pragma: string;
              cacheControl: string;
          }
        | any
    > => {
        const accounts = msalInstance.getAllAccounts();

        const res = await msalInstance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
        });

        return {
            authorization: `Bearer ${res.accessToken}`,
            pragma: 'no-cache',
            'cache-control': 'no-cache',
        };
    };

    getOrders = async (filter: IOrderFilter, isMaster: boolean): Promise<IOrderRes> => {
        return await postRequest(
            `${BaseUrl}purchaseorders/getPurchaseOrders`,
            { ...filter, isMaster },
            {
                headers: await this.getAuthHead(),
            },
        );
    };

    getOrderStages = async (): Promise<IProductionStageRes[]> => {
        return await getRequest(`${BaseUrl}purchaseorders/getProductionStages`, {
            headers: await this.getAuthHead(),
        });
    };

    assignProductionStatus = async (data: ICheckedValues): Promise<any> => {
        return await postRequest(`${BaseUrl}purchaseorders/updateOrderProductionStage`, data, {
            headers: await this.getAuthHead(),
        });
    };

    editStages = async (data: ICheckedValues): Promise<any> => {
        return await postRequest(`${BaseUrl}purchaseorders/updateOrderPreProductionStage`, data, {
            headers: await this.getAuthHead(),
        });
    };

    editShippingInfo = async (data: IEditShippingReq): Promise<any> => {
        return await postRequest(`${BaseUrl}purchaseorders/updateShippingInfo`, data, {
            headers: await this.getAuthHead(),
        });
    };

    updateplannedCompletionTime = async (data: IUpdateEstimatedReq): Promise<any> => {
        return await postRequest(`${BaseUrl}purchaseorders/updatePlannedCompletionDate`, data, {
            headers: await this.getAuthHead(),
        });
    };

    getOrderDetails = async (poDocId: string): Promise<IOrder> => {
        return await getRequest(`${BaseUrl}purchaseorders/detail/${poDocId}`, {
            headers: await this.getAuthHead(),
        });
    };

    postComments = async (cmnt: ICommentRes): Promise<any> => {
        return await postRequest(`${BaseUrl}purchaseorders/insertMessage`, cmnt, {
            headers: await this.getAuthHead(),
        });
    };

    getComments = async (poDocId: string): Promise<IComment[]> => {
        return await getRequest(`${BaseUrl}purchaseorders/getMessages/${poDocId}`, {
            headers: await this.getAuthHead(),
        });
    };

    getLogisticsCompanies = async (): Promise<ILogisticCompany[]> => {
        return await getRequest(`${BaseUrl}purchaseorders/getLogisticsCompanies`, {
            headers: await this.getAuthHead(),
        });
    };

    getFiles = async (poDocId: string, fileType: string): Promise<ITechSheetRes[]> => {
        return await getRequest(`${BaseUrl}files/${poDocId}/${fileType}`, {
            headers: await this.getAuthHead(),
        });
    };

    getSearchBarData = async (isMaster: boolean) => {
        return await getRequest(`${BaseUrl}purchaseorders/getSearchBarData/${isMaster}`, {
            headers: await this.getAuthHead(),
        });
    };

    getSgtAuditStatuses = async () => {
        return await getRequest(`${BaseUrl}purchaseorders/sgtaudit/status`, {
            headers: await this.getAuthHead(),
        });
    };

    getPreproductionStageDetails = async () => {
        return await getRequest<{ elements: IPreproductionStageDetail[] }>(
            `${BaseUrl}purchaseorders/getPreproductionStageDetails`,
            {
                headers: await this.getAuthHead(),
            },
        );
    };

    uploadFile = async (data: any): Promise<any> => {
        return await postRequest(`${BaseUrl}files/uploadFile`, data, {
            headers: await this.getAuthHead(),
        });
    };

    uploadShippingFile = async (data: any): Promise<any> => {
        return await postRequest(`${BaseUrl}files/uploadShippingFile`, data, {
            headers: await this.getAuthHead(),
        });
    };
    uploadInvoiceFile = async (data: any): Promise<any> => {
        return await postRequest(`${BaseUrl}files/upload/invoice`, data, {
            headers: await this.getAuthHead(),
        });
    };
    uploadSgtAuditFile = async (data: any): Promise<any> => {
        return await postRequest(`${BaseUrl}files/upload/audit-result`, data, {
            headers: await this.getAuthHead(),
        });
    };

    deleteFile = async (data: any): Promise<any> => {
        return await delRequest(
            `${BaseUrl}files/${data.orderId}/${data.fileName}/${data.fileType}`,
            {
                headers: await this.getAuthHead(),
            },
        );
    };

    editOrderInfo = async (data: any): Promise<any> => {
        return await postRequest(`${BaseUrl}purchaseorders/UpdateOrderSummary`, data, {
            headers: await this.getAuthHead(),
        });
    };

    editOrderAckStatus = async (data: any): Promise<any> => {
        return await postRequest(
            `${BaseUrl}purchaseorders/updateAcknowledgementOrderStatuses`,
            data,
            {
                headers: await this.getAuthHead(),
            },
        );
    };

    downloadOrderExcel = async (filter: IOrderFilter): Promise<IOrderExcelDownloadRes> => {
        return await postRequest(
            `${BaseUrl}purchaseorders/getPurchaseOrdersAsExcel`,
            { ...filter },
            {
                headers: await this.getAuthHead(),
            },
        );
    };

    downloadShippingDetailExcel = async (filter: IOrderFilter): Promise<IOrderExcelDownloadRes> => {
        return await postRequest(
            `${BaseUrl}purchaseorders/getShippingDetailsAsExcel`,
            { ...filter },
            {
                headers: await this.getAuthHead(),
            },
        );
    };

    downloadBase64File = async (filePath: string): Promise<IDownloadBase64Res> => {
        return await getRequest(`${BaseUrl}${filePath}`, {
            headers: await this.getAuthHead(),
        });
    };
}

export const orderService = new OrderService();
